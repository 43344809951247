<template>
  <div id="listWidget" class="mb-5">
    <ul class="breadcrumb">
      <li v-if="zone" class="breadcrumb-item">{{ zone?.name }}</li>
      <li class="breadcrumb-item active">컨트롤러 설정</li>
    </ul>
    <div>
      <h4 class="mb-3">컨트롤러 설정</h4>    
      <p class="mb-1">
        1. 컨트롤러의 <code style="font-size: 100%">세팅버튼</code>을 눌러서 세팅
        모드로 진입하시고 LED를 확인하십시오.
      </p>
      <!-- <p class="mb-1">
        2.<code style="font-size: 100%">컨트롤러 검색 버튼</code>을 눌러서 설정
        가능한 컨트롤러 정보를 확인하십시오
      </p> -->
      <!-- <p class="mb-1">
        3. 컨트롤러 Wifi 목록중 <code style="font-size: 100%">연결 버튼</code>을
        눌러서 컨트롤러 AP에 접속하십시오. 비밀번호는
        <code style="font-size: 100%">[{{ getAPPWD }}]</code>입니다.
      </p> -->
      <p class="mb-1">
        2. <code style="font-size: 100%">컨트롤러 AP 연결버튼</code>을
        눌러서 컨트롤러 AP에 접속하십시오.
      </p>
      <p class="mb-1">
        3. 연결된 컨트롤러에 로컬 wifi 네트워크를 설정해주십시오.
      </p>
      <p class="mb-1">4. 연결된 컨트롤러를 서버에 등록</p>
      <p>
        <code style="font-size: 100%"
          >※ 주의)컨트롤러 설정시 한 장치만 활성화 되어 있어야합니다.</code
        >
      </p>

      <CardBody>
        <button
          type="button"        
          class="btn btn-primary"
          style="--bs-btn-padding-y: 0.16rem"
          @click="onConnect"
        >컨트롤러 AP 연결</button>
      </CardBody>
    </div>
    
    <!-- <div class="d-flex mb-3">
      <button
        type="button"        
        class="btn btn-primary"
        style="--bs-btn-padding-y: 0.16rem"
        @click="onConnect"
      >컨트롤러 AP 연결</button>
      
    </div> -->
    <!-- <div class="d-flex mb-3">
      <h6 class="mb-12"> connected : {{connectedWifi.ssid}} </h6>
    </div>
    <div class="d-flex mb-3">
      <h6 class="mb-12"> connectedController : {{connectedController._id}} </h6>      
    </div> -->
    <!-- <div class="d-flex mb-3">
      <button
        type="button"        
        class="btn btn-primary"
        style="--bs-btn-padding-y: 0.16rem"
        @click="onSetting"
      >컨트롤러 Wifi 설정</button>
    </div>
    <div class="d-flex mb-3">
      <button
        type="button"        
        class="btn btn-primary"
        style="--bs-btn-padding-y: 0.16rem"
        @click="onRegist"
      >컨트롤러 등록</button>
    </div> -->

    <hr
      class="mb-4"
      v-if="
        (settingParams && settingParams.name) || zone?.controllers?.length > 0
      "
    />

    <h6 class="mb-3" v-if="settingParams && settingParams.name">
      wifi:{{ settingParams.name }}
    </h6>

    <div class="row">
      <ControllerItem
        v-for="(item, index) in zone?.controllers"
        :key="item._id"
        :index="index"
        :data="item"
        v-on:onDeleteClick="onReceivedDeleteClick"
      />
      <!-- <button
      type="submit"
      class="btn btn-primary btn-lg d-block w-40 fw-40 mb-4"
      @click="onAddControllerClick"
    >
      컨트롤러 등록
    </button> -->
    </div>

    <!-- wifiListModal -->
    <PopDialog
      title="검색된 컨트롤러 wifi목록"
      :visible="wifiListModal.visible"
      :cancelCallback="wifiListModal.hide"
      :cancelText="'닫기'"
    >
      <div class="row" v-for="(item, index) in wifiItems" :key="item._id">
        <WifiItem
          :index="index"
          :data="item"
          v-on:onConnectClick="onReceivedConnectClick"
        />
      </div>
    </PopDialog>
    <!-- wifiConnectModal -->
    <PopDialog
      title="컨트롤러 AP 연결정보 설정"
      :visible="wifiConnectModal.visible"
      :okCallback="onConnect"
      :cancelCallback="wifiConnectModal.hide"
      :okText="'연결'"
      :cancelText="'닫기'"
    >
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="ssid 입력해주세요."
        v-model="ap_wifi.ssid"
      />
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        placeholder="password 입력해주세요."
        v-model="ap_wifi.password"
      />
    </PopDialog>

    <!-- controllerWifiSettingModal -->
    <PopDialog
      title="컨트롤러 Wifi 연결정보 설정"
      :visible="controllerWifiSettingModal.visible"
      :okCallback="onSettingAndRegist"
      :cancelCallback="controllerWifiSettingModal.hide"
    >
      시리얼 넘버 : {{ connectedController._id }}<br />
      모델 코드 : {{ connectedController.model }}<br />
      펌웨어 버전 : {{ connectedController.version }}<br />

      <hr class="mb-3" />

      <input
        type="text"
        class="form-control form-control-lg fs-15px mb-2 col"
        placeholder="ssid 입력해주세요."
        v-model="settingParams.ssid"
      />
      <input
        type="text"
        class="form-control form-control-lg fs-15px col"
        placeholder="password 입력해주세요."
        v-model="settingParams.pwd"
      />
    </PopDialog>

    <!-- addControllerModal -->
    <PopDialog
      title="컨트롤러추가"
      :visible="addControllerModal.visible"
      :okCallback="onRegist"
      :cancelCallback="addControllerModal.hide"
    >
      시리얼 넘버 :
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        v-model="connectedController._id"
      />
      모델 코드 :
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        v-model="connectedController.model"
      />
      펌웨어 버전 :
      <input
        type="text"
        class="form-control form-control-lg fs-15px"
        v-model="connectedController.version"
      />
    </PopDialog>
  </div>
</template>
<script setup>
import {
  ref,
  reactive,
  getCurrentInstance,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  
} from 'vue';
import { useStore } from 'vuex';
import Axios, { ZoneApi, GroupApi, CtrlApi } from '@/api';

import { useRouter, useRoute ,onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import PopDialog from '@/common/components/popup/PopDialog.vue';
import useModal from '@/common/hooks/useModal';
import CardBody from '@/common/template/components/bootstrap/CardBody.vue';

import ControllerItem from './components/ControllerItem.vue';
import WifiItem from './components/WifiItem.vue';
import { MCApi } from '@/api/mcUrls';
import { DeviceDefault, SettingConfig } from '@/common/constants';
import apAxios from 'axios';
import useMobile from '@/common/hooks/useMobile';

const { emitter, mobile, logger } =
  getCurrentInstance().appContext.config.globalProperties;
const router = useRouter();
const route = useRoute();
const props = defineProps(['data']);
const store = useStore();
const account = computed(() => store.getters['account']);
const wifiListModal = reactive(useModal());
const wifiConnectModal = reactive(useModal());
const controllerWifiSettingModal = reactive(useModal());
const addControllerModal = reactive(useModal());
const { requestTransferController } = useMobile();

const forceRoute = ref(false);

const zone = ref(undefined);
const wifiItems = ref([]);

const ap_wifi = ref({
  ssid: DeviceDefault.AP_SSID,
  password: DeviceDefault.AP_PWD,
});

// TODO : 서버에서 관리되는 모델의 펌웨어 버전 및 다운로드 url 적용 해야함.
const settingParams = ref({
  // ssid: 'MOTIZEN_5GHz',
  // pwd: 'motizen$$$',
  ssid : '',
  pwd :'',
  firmware_version: '',
  firmware_url: '',
  uuid: '',
  device_key: '',
  mqtt_url: process.env.VUE_APP_CTRL_MQTT_DOMAIN,
  mqtt_username: '',
  mqtt_password: '',
});

const connectedWifi = ref({ ssid: '' });
const connectedController = ref({
  serial_no: '',
  version: '',
  model: '',
});

let requestCount = 0;

onBeforeMount(() => {
  emitter.emit('onChangeHeaderSetting',{title:'네트워크 설정',isShowMenu:false,isShowZone:false,isShowProfile:false});  
});
onBeforeUnmount(() =>{
  emitter.emit('onChangeHeaderSetting',{title:'',isShowMenu:true,isShowZone:true,isShowProfile:true});
})
onMounted(() => {
  requestZone()
  console.log('ControllerSettingPage.onMounted => zone : ', zone.value);
});

watch(zone, () => {
  console.log('ControllerSettingPage.watch => zone : ', zone.value);
});

onBeforeRouteLeave((to, from) => {
  console.log('ControllerSettingPage,onBeforeRouteLeave => to : ',to)
  console.log('ControllerSettingPage,onBeforeRouteLeave => from : ',from)
  if(to.path === '/settings/zones'){
    
    if(forceRoute.value)return true;

    emitter.emit('showPopAlert', {
      description:
        '컨트롤러 설정이 완료되지 않았습니다.\n컨트롤러 설정을 종료 하시겠습니까?',
        okCallback:() =>{
          forceRoute.value = true
          router.back()
        },
        cancelCallback:() =>{}
    });
    return false
  }  
  
})
const getAPPWD = computed(() => {
  return DeviceDefault.AP_PWD;
});
// 컨트롤러 검색 :
const onSearch = () => {
  // show loading
  // add event
  emitter.emit('showLoading');
  emitter.on('onSearchResult', (payload) => {
    emitter.emit('hideLoading');

    // remove event
    emitter.off('onSearchResult');

    logger.log('ControllerSettingPage.onSearchResult => payload : ' + payload);
    // TODO hide loading

    if (payload.data) {
      wifiItems.value = JSON.parse(
        decodeURIComponent(window.atob(payload.data)),
      );
      logger.log(
        'ControllerSettingPage.onSearchResult => wifiItems : ' +
          JSON.stringify(wifiItems.value),
      );

      if (wifiItems.value.length == 0) {
        // show wifimodal
        showPopAlert('검색 결과가 없습니다.');
        return;
      }

      wifiListModal.show();
    }
  });
  mobile.searchWifi(
    JSON.stringify({ prefix: 'inshow', callback: 'onSearchResult' }),
  );
};

// 컨트롤러 연결 :
const onConnect = () => {
  wifiConnectModal.hide();
  // add event
  emitter.emit('showLoading');
  emitter.on('onConnectResult', (payload) => {
    emitter.emit('hideLoading');

    // remove event
    emitter.off('onConnectResult');

    logger.log(
      `ControllerSettingPage.onConnect => onConnectResult : payload : ${payload} `,
    );

    if(payload.result){

      let decoded = JSON.parse(decodeURIComponent(window.atob(payload.data)));
      // logger.log(`ControllerSettingPage.onConnect => onConnectResult : decoded : ${JSON.stringify(decoded)}`);

      if (decoded) {
        if (decoded.message) {
          showPopAlert(decoded.message);
          return;
        }
        // connectedController.value = decoded.device
        let arr = decoded.device.serial_no.split('_');
        connectedController.value.model = arr[0];
        connectedController.value._id = decoded.device.serial_no;
        connectedController.value.version = decoded.device.version;
        // 컨트롤러가 통신할 local wifi 세팅 창을 연다.
        controllerWifiSettingModal.show();
        return;
      }
      return;
    }
    
    mobile.disConnectWifi(JSON.stringify(ap_wifi.value));
    showPopAlert('연결에 실패하였습니다.');
  });

  logger.log(`ControllerSettingPage.onConnect => payload:${JSON.stringify({
      ssid: ap_wifi.value.ssid,
      password: ap_wifi.value.password,
      bssid: ap_wifi.value.bssid||undefined,
      callback: 'onConnectResult',
    })}`)

  mobile.connectWifi(
    JSON.stringify({
      ssid: ap_wifi.value.ssid,
      password: ap_wifi.value.password,
      bssid: ap_wifi.value.bssid||undefined,
      callback: 'onConnectResult',
    }),
  );
};

// 컨트롤러 설정 :
const onSetting = () => {
  return new Promise((resolve, reject) => {
    //
    logger.log(
      'ControllerSettingPage.onSetting => wifi : ' + settingParams.value,
    );
    // controllerWifiSettingModal.hide();

    emitter.on('onSettingResult', (payload) => {
      // emitter.emit('hideLoading');

      // remove event
      emitter.off('onSettingResult');

      logger.log(
        `ControllerSettingPage.onSetting => onSettingResult : payload : ${JSON.stringify(
          payload,
        )} `,
      );
      // if (payload.result) {
        mobile.disConnectWifi(JSON.stringify(ap_wifi.value));
        // addControllerModal.show();
        resolve();
        return;
      // } else {
      //   let decoded = JSON.parse(decodeURIComponent(window.atob(payload.data)));
      //   logger.log(
      //     `ControllerSettingPage.onSetting => onSettingResult : decoded : ${JSON.stringify(
      //       decoded,
      //     )} `,
      //   );
      //   if (decoded) {
      //     if (decoded.message) {
      //       // showPopAlert(decoded.message);
      //       reject(decoded.message);
      //       return;
      //     }
      //   }
      //   // showPopAlert('설정에 실패하였습니다.');
      //   reject('설정에 실패하였습니다.');
      // }
    });

    // emitter.emit('showLoading');
    mobile.settingDevice(
      JSON.stringify({ ...settingParams.value, callback: 'onSettingResult' }),
    );
  });
};

// 컨트롤러 등록 :
const onRegist = async () => {
  const request = () => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        try {
          // const exControllerResult = await Axios.get(CtrlApi.CTRL_INFO.replace(':id', connectedController.value._id));
          // if(exControllerResult.resultCode === '0000'){
          //   if(exControllerResult.resultData) {
          //     reject("이미 등록된 컨트롤러입니다.")
          //     return;
          //   }      
          // }

          const result = await Axios.post(
            ZoneApi.REGIST_ZONE_CTRLS.replace(':id', route.params.zoneId),
            connectedController.value,
          );

          if (result?.resultCode === "0000") {
            await requestZone();
          }
          resolve(result);
        } catch (e) {
          resolve(e);
        }
      }, SettingConfig.requestInterval);
    });
  }

  return new Promise(async (resolve, reject) => {
    try {
      let result = undefined;
      for (let i = 0; i < SettingConfig.requestCount; i++) {
        result = await request();
        if (result?.resultCode === "0000") {
          resolve();
          return;
        }
        if (result?.resultCode === "1001") {
          reject(result);
          return;
        }
      }

      reject(result?.resultMessage?.length > 0 ? result.resultMessage : "컨트롤러 등록에 실패했습니다.");
    } catch (e) {
      console.log(e);
      reject("컨트롤러 추가 중 오류가 발생하였습니다.")
    }
  });
};

const onSettingAndRegist = async () => {
  try {
    emitter.emit('showLoading');
    await onSetting();
    await onRegist();
    controllerWifiSettingModal.hide();
    emitter.emit('hideLoading');

    emitter.emit('showPopAlert', {
      description: '컨트롤러가 등록되었습니다.\n방 설정 화면으로 이동합니다.',
      okCallback: () => router.replace(`/settings/zones/${route.params.zoneId}/groups`),
      cancelCallback: () => router.replace(`/settings/zones/${route.params.zoneId}/groups`),
    });
  } catch (e) {
    controllerWifiSettingModal.hide();
    emitter.emit('hideLoading');

    if (e.resultCode === "1001") {
      emitter.emit('showPopConfirm', {
        title: "등록된 컨트롤러",
        description: "이미 등록된 컨트롤러입니다.\n이관 받으시겠습니까?",
        okCallback: async () => {
          const result = await requestTransferController(
            connectedController.value,
            account.value._id,
            e.resultData._id,
            route.params.zoneId
          );

          if (result?.resultCode === "0000") {
            forceRoute.value = true;
            emitter.emit("showPopAlert", {
              description: '이관이 완료되었습니다.',
              okCallback: () => router.go(-1),
              cancelCallback: () => router.go(-1),
            });
          } else {
            emitter.emit("showPopAlert", {
              description: '이관에 실패했습니다.'
            });
          }
        }
      });
    } else {
      console.log(e);
      showPopAlert(e);
    }
  }
};

const onGetDeviceInfo = async () => {
  logger.log(`ControllerSettingPage.onGetDeviceInfo`);

  emitter.emit('showLoading');
  emitter.on('onGetDeviceResult', (payload) => {
    emitter.emit('hideLoading');

    logger.log(
      `ControllerSettingPage.onGetDeviceInfo => onGetDeviceResult : payload : ${payload.data} `,
    );

    const decoded = JSON.parse(decodeURIComponent(window.atob(payload.data)));
    logger.log(
      `ControllerSettingPage.onGetDeviceInfo => onGetDeviceResult : decoded : ${JSON.stringify(
        decoded,
      )} `,
    );
    logger.log(
      `ControllerSettingPage.onGetDeviceInfo => onGetDeviceResult : decoded : ${decoded} `,
    );
    if (decoded.message) {
      showPopAlert(decoded.message);
    }
    // remove event
    emitter.off('onGetDeviceResult');
  });

  mobile.getDeviceInfo(JSON.stringify({ callback: 'onGetDeviceResult' }));
};

const onOpenSetting = async() =>{
  mobile.openSetting(JSON.stringify({ callback: 'onOpenSettingResult' }));
}
const showPopAlert = (msg) => {
  emitter.emit('showPopAlert', {
    description: msg,
  });
};
// wifiListModal - onConnectClick
const onReceivedConnectClick = (payload) => {
  try {
    wifiListModal.hide();
    console.log(
      'ControllerSettingPage.onReceivedConnectClick() => payload : ' +
        JSON.stringify(payload),
    );

    ap_wifi.value.ssid = payload.ssid;
    ap_wifi.value.bssid = payload.bssid;
    ap_wifi.value.password = 'inshow22'; //DeviceDefault.AP_PWD

    wifiConnectModal.show();
  } catch (e) {
    logger.log(e);
  }
};

const requestDeleteController = async (payload) => {
  try {
    console.log(
      'ControllerSettingPage.onReceivedDeleteClick() => payload : ' +
        JSON.stringify(payload),
    );
    const result = await Axios.delete(
      CtrlApi.DELETE_CTRL.replace(':id', payload.id),
    );
    if (result.resultCode === '0000') {
      requestZone();
      store.commit('home/deleteController', {
        zoneId: route.params.zoneId,
        controllerId: payload.id,
      });
      return;
    }

    emitter.emit('showPopAlert', {
      description: result.resultMessage,
    });
  } catch (error) {
    console.log(error)
    emitter.emit('showPopAlert', {
      description: '컨트롤러 삭제에 실패했습니다.',
    });
  }
};

const requestZone = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Axios.get(
        ZoneApi.ZONE_INFO.replace(':id', route.params.zoneId),
      );
      if (result.resultCode === '0000') {
        zone.value = result.resultData;
        resolve(result);
      } else {
        // emitter.emit('showPopAlert', {
        //   description: result.resultMessage,
        // });
        reject(result.resultMessage);
      }
    } catch (error) {
      // emitter.emit('showPopAlert', {
      //   description: '영역 조회에 실패했습니다.',
      // });
      reject("영역 조회에 실패했습니다.");
    }
  });
};

const onReceivedDeleteClick = async (payload) => {
  emitter.emit('showPopConfirm', {
    description: '컨트롤러를 삭제하시겠습니까?',
    okCallback: () => requestDeleteController(payload)
  });  
};
</script>
